import { template as template_c5009e5558e74be6b8d7ed074bd8df65 } from "@ember/template-compiler";
const FKText = template_c5009e5558e74be6b8d7ed074bd8df65(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
