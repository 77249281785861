import { template as template_a14e6464b4c940f79f5c5fab4f606ff3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a14e6464b4c940f79f5c5fab4f606ff3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
