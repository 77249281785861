import { template as template_f5b8115e7e87402997bc4ccb9a4af4bf } from "@ember/template-compiler";
const FKLabel = template_f5b8115e7e87402997bc4ccb9a4af4bf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
